.footer-container {
    display: flex;
    justify-content: center;
}

.footer-container > p {
    font-size: 10px;
    padding: 0 15px;
    color: white;
    opacity: 0.5;
    text-decoration: none;
}

.footer-container p > a {
    color: white;
    text-decoration: none;
}

@media (max-width: 500px) {
    .footer-container > p {
        padding: 0 5px;
        font-size: 8px;
    }
    
    .footer-container p > a {
        font-size: 8px;
    }
}