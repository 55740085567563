.vertical-align-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
} 

.container {
    padding: 0 15px;
    display: flex;
    max-width: 1440px;
    align-content: center;
    flex-direction: column;
    width: 100%;
}

