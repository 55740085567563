.hero-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.hero-slogan {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 60px;
    letter-spacing: 2.3px;
    line-height: 72px;
    color: white;
    max-width: 437px;
}

@media (max-width: 500px) {
    .hero-slogan {
        max-width: 100%;
        letter-spacing: 0.67px;
        line-height: 45px;
        font-size: 35px;
        text-align: center;
        padding: 0px 10px;
    }
}

.hero-slogan span {
    color: #1AD643;
    text-transform: uppercase;
    font-weight: bold;
}

.hero-image {
    width: 100%;
    max-width: 482px;
}

@media (max-height: 950px) {
    .hero-image {
        max-height: 80vh;
        max-width: 46vh;
    }
}

.hero-text {
    max-width: 437px;
}

@media (max-width: 500px) {
    .hero-text p {
        text-align: center;
        padding: 0 10px;
    }
}

.hero-text p:nth-child(1) {
    font-size: 18px;
    color: white;
    letter-spacing: 0.79px;
    line-height: 24px;
}

.hero-text p:nth-child(2) {
    font-size: 14px;
    color: #1AD643;
    letter-spacing: 0.61px;
    line-height: 20px;
}

.hero-text p:nth-child(3) {
    font-size: 14px;
    color: #ACACAC;
    letter-spacing: 0.61px;
    line-height: 20px;    
}