@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,700&display=swap);
body {
  background-color: #151515;
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button-container {
    display: flex;
    padding-top: 46px;
    padding-left: 15px;
    max-height: 5vh;
}

@media (max-width: 500px) {
    .button-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .appstore-button {
        padding-left: 0;
        padding-right: 15px;
    }
}

.logo {
    height: 52px;
    width: 52px;
}

.appstore-button {
    width: 156px;
    height: 52px;
    padding-left: 15px;
}
.hero-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.hero-slogan {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 60px;
    letter-spacing: 2.3px;
    line-height: 72px;
    color: white;
    max-width: 437px;
}

@media (max-width: 500px) {
    .hero-slogan {
        max-width: 100%;
        letter-spacing: 0.67px;
        line-height: 45px;
        font-size: 35px;
        text-align: center;
        padding: 0px 10px;
    }
}

.hero-slogan span {
    color: #1AD643;
    text-transform: uppercase;
    font-weight: bold;
}

.hero-image {
    width: 100%;
    max-width: 482px;
}

@media (max-height: 950px) {
    .hero-image {
        max-height: 80vh;
        max-width: 46vh;
    }
}

.hero-text {
    max-width: 437px;
}

@media (max-width: 500px) {
    .hero-text p {
        text-align: center;
        padding: 0 10px;
    }
}

.hero-text p:nth-child(1) {
    font-size: 18px;
    color: white;
    letter-spacing: 0.79px;
    line-height: 24px;
}

.hero-text p:nth-child(2) {
    font-size: 14px;
    color: #1AD643;
    letter-spacing: 0.61px;
    line-height: 20px;
}

.hero-text p:nth-child(3) {
    font-size: 14px;
    color: #ACACAC;
    letter-spacing: 0.61px;
    line-height: 20px;    
}
.footer-container {
    display: flex;
    justify-content: center;
}

.footer-container > p {
    font-size: 10px;
    padding: 0 15px;
    color: white;
    opacity: 0.5;
    text-decoration: none;
}

.footer-container p > a {
    color: white;
    text-decoration: none;
}

@media (max-width: 500px) {
    .footer-container > p {
        padding: 0 5px;
        font-size: 8px;
    }
    
    .footer-container p > a {
        font-size: 8px;
    }
}
.vertical-align-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
} 

.container {
    padding: 0 15px;
    display: flex;
    max-width: 1440px;
    align-content: center;
    flex-direction: column;
    width: 100%;
}


