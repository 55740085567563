.button-container {
    display: flex;
    padding-top: 46px;
    padding-left: 15px;
    max-height: 5vh;
}

@media (max-width: 500px) {
    .button-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .appstore-button {
        padding-left: 0;
        padding-right: 15px;
    }
}

.logo {
    height: 52px;
    width: 52px;
}

.appstore-button {
    width: 156px;
    height: 52px;
    padding-left: 15px;
}